import React, { useState, useEffect } from 'react';
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { BaseSection, useGlobal, fromLabel, useQuery, Checkbox, Typography, toId, Currency } from 'up-form';
import {Grid, Button, Paper, withStyles, makeStyles, TableRow, TableCell, Card, CardContent, CircularProgress, Table, TableBody} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import Payment from "@material-ui/icons/Payment";
import CalendarToday from "@material-ui/icons/CalendarToday";
import {useDispatch, useSelector} from "react-redux";
import {createStripeIntentAndSubscription, useMetadata} from "up-state";
import { change, formValueSelector } from "redux-form";
import UpApi from "up-api";
import {acceptance} from "redux-form-validators";
import { Trans, useTranslation } from "react-i18next";

let setGotPricing = false;
let pricing = {};

const cellStyles = makeStyles((theme) => ({
    root: {
      padding: ".25em",
      borderBottom: "none",
    },
    head: {
      fontWeight: "bold",
    },
  }));
  

const StripePaymentPlan = withStyles((theme) => {
    return {
      stepConfirmed: {
        opacity: ".5"
      },
      confirmationRoot: {
        borderColor: theme.palette.secondary.light
      },
      paddingMedium: {
        [theme.breakpoints.up('md')]: {
          paddingLeft: theme.spacing(10),
        },
      },
      paddingAroundComponents: {
        paddingTop: "20px", 
        paddingLeft: "80px"
      }
    };
  })(({ section, form, classes }) => {
    const { t } = useTranslation();
    const prefix = "Section.StripePaymentPlan";
    const stripe = useStripe();
    const dispatch = useDispatch();
    const elements = useElements();
    const {
        provider,
        links: { home, paymentPlan },
        component: {
            Checkout: {
              fullPaymentProvider,
              planPaymentProvider,
              hideTotalAndDiscount
            } = {}
          }
    } = useGlobal();
    const [success, setSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const {data: {clientId: clientSecret} = {}} = useSelector((state) => state.createStripeIntentAndSubscription || {});
    const {data: application} = useSelector((state) => state.application || {});
    let {
        opportunity: {opportunityCRMId: opportunityId} = {},
        payment: {methodId, firstPaymentTransactionId, paymentPlanStartDate, paymentPlanFrequencyId, } = {},
      } = application || {};
    const {
      data: {
        paymentMethods,
        paymentProviders
      } = {},
    } = useMetadata();
    const planPaymentMethod =
    paymentMethods && fromLabel(paymentMethods, "Payment Plan");

    const selector = formValueSelector(form);
    const stateProductId = useSelector(
        (state) => selector(state, "chooseCourse.course.productId") || {}
    );
    const { pid: queryproductId } = useQuery();
    const productId =
        Object.keys(stateProductId).length > 0 ? stateProductId : queryproductId;
        
    const isPaymentPlan =
    methodId && planPaymentMethod && methodId === planPaymentMethod.id;
    const paymentProvider =
      isPaymentPlan ? planPaymentProvider : fullPaymentProvider;
    const {
    calculatedDiscountAmount,
    extendedAmount: discountedTotalAmount,
    installments,
    } = (pricing &&
    pricing.paymentProviders &&
    pricing.paymentProviders[paymentProvider]) ||
    {};

    const {
        noOfPayments,
        installmentAmount,
        paymentPlanDepositAmount: depositAmount,
      } = (Array.isArray(installments) &&
      paymentPlanFrequencyId &&
        installments.find(
          ({ paymentFrequency }) => paymentFrequency === paymentPlanFrequencyId
        )) ||
        {};

    const [loadedPricing, setLoadedPricing] = useState(false);

    useEffect(() => {
      console.log(loadedPricing)
    }, [loadedPricing]);

    useEffect(() => {
    if (
        !opportunityId &&
        !setGotPricing &&
        productId !== undefined &&
        Object.keys(productId).length > 0
    ) {
        setGotPricing = true;
        UpApi.productPricing(productId, provider).then((result) => {
        pricing = result;
        setLoadedPricing(true)
        });
    } else if (opportunityId && !setGotPricing) {
        setGotPricing = true;
        UpApi.applicationPricing(opportunityId, provider).then((result) => {
        pricing = result;
        setLoadedPricing(true)
        });
      }
    }, [opportunityId, provider, productId]);

    useEffect(() => {
        if (!clientSecret && opportunityId && firstPaymentTransactionId) {
            dispatch(
              createStripeIntentAndSubscription({
                provider: provider,
                createType: "SetupIntent"
              })
            );
        }
      }, [dispatch, provider, clientSecret, opportunityId, firstPaymentTransactionId]);

    useEffect(() => {
        if (installmentAmount && noOfPayments) {
            dispatch(change(form, `${section}.noOfPayments`, noOfPayments));
            dispatch(change(form, `${section}.installmentAmount`, installmentAmount));
        }
    }, [installmentAmount, noOfPayments, dispatch, form, section]);


    const handleError = (error) => {
        setIsLoading(false);
        setErrorMessage(error.message);
      }

    const handleClick = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);

        const result = await elements.submit();

        if (result.error) {
            // Handle error here
            handleError(result.error);
        } 
        else {
            const { error, setupIntent } = await stripe.confirmSetup({
                elements,
                clientSecret,
                confirmParams: {
                return_url: home
                },
                redirect: "if_required"
            });

            
            if (error) {
                // This point is only reached if there's an immediate error when
                // confirming the setup. Show the error to your customer (for example, payment details incomplete)
                handleError(error);
            } 
            else if (setupIntent.payment_method) {
                dispatch(change(form, `${section}.paymentMethodId`, setupIntent.payment_method));
                setSuccess(true);
                setErrorMessage(null);
            }
        }
    }
    
    const isConfirmed = useSelector(
        (state) => selector(state, `${section}.confirmed`) || false
      );
      
    const {
      productPriceCurrency,
      productName,
      productPrice: totalAmount,
    } = pricing || {};

    let paymentProviderState = useSelector((state) => selector(state, `checkout.paymentProvider`));

    let paymentPlanFrequency = useSelector((state) =>
        selector(state, `checkout.paymentPlanFrequency`)
      );

    function RenderCurrency({ value, signDisplay = "auto", ...other }) {
      return (
        <Currency
          component="span"
          formatOptions={{ signDisplay }}
          currency={productPriceCurrency}
          value={Number(value)}
          {...other}
        />
      );
    }

    function PaymentSummary({
      totalAmount,
      discountedTotalAmount,
      noOfPayments,
      installmentAmount,
      depositAmount,
      installmentFrequency,
    }) {
      const classes = cellStyles();
      function SummaryLine({ i18nKey, children }) {
        return (
          <TableRow>
            <TableCell variant="head" classes={classes}>
              {t(`${prefix}.paymentSummary.${i18nKey}`)}:
            </TableCell>
            <TableCell classes={classes} style={{ textAlign: "right" }}>
              {children}
            </TableCell>
          </TableRow>
        );
      }
      return (
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h2" gutterBottom>
              <Payment
                fontSize="large"
                color="secondary"
                style={{ verticalAlign: "middle", marginRight: ".5em" }}
              />
              {t(`${prefix}.paymentSummary.title`)}
            </Typography>
            <Table>
              <TableBody>
                  <SummaryLine i18nKey="courseName">
                      {productName}
                  </SummaryLine>
                {Object.keys(pricing).length > 0 ? null : <CircularProgress size="1em" style={{ marginRight: "1em" }} />}
                {!!totalAmount && !hideTotalAndDiscount && (
                  <SummaryLine i18nKey="totalAmount">
                    <RenderCurrency value={totalAmount} />
                  </SummaryLine>
                )}
                {!hideTotalAndDiscount && !!totalAmount && (
                  <SummaryLine i18nKey="discountAmount">
                    <RenderCurrency
                      signDisplay="always"
                      value={-Number(calculatedDiscountAmount || 0)}
                    />
                  </SummaryLine>
                )}
                {!!discountedTotalAmount && (
                  <SummaryLine i18nKey="discountedTotalAmount">
                    <RenderCurrency value={discountedTotalAmount} />
                  </SummaryLine>
                )}
              </TableBody>
            </Table>
  
            {noOfPayments >= 2 && paymentProviderState && paymentProviderState?.id === toId(fromLabel(paymentProviders, "Stripe Subscription AU")) && (
              <>
                {Object.keys(pricing).length > 0 ? null : <CircularProgress size="1em" style={{ marginRight: "1em" }} />}
                <Typography variant="h2" gutterBottom>
                  <CalendarToday
                    fontSize="large"
                    color="secondary"
                    style={{ verticalAlign: "middle", marginRight: ".5em" }}
                  />
                  {t(`${prefix}.paymentSchedule.title`)}
                </Typography>
                <Table>
                  <TableBody>
                    {!!depositAmount && (
                      <SummaryLine i18nKey="depositAmount">
                        <RenderCurrency value={depositAmount} />
                      </SummaryLine>
                    )}
                    <SummaryLine i18nKey="noOfPayments">
                      {noOfPayments}
                    </SummaryLine>
                    {installmentFrequency && (
                      <SummaryLine i18nKey="installmentFrequency">
                        {installmentFrequency}
                      </SummaryLine>
                    )}
                    {installmentAmount && (
                      <SummaryLine i18nKey="installmentAmount">
                        <RenderCurrency value={installmentAmount} />
                      </SummaryLine>
                    )}
                  </TableBody>
                </Table>
              </>
            )}
          </CardContent>
        </Card>
      );
    }

    return (
    <BaseSection section={section} title="Payment Plan">
        <Grid  item container xs={12} md={8} justifyContent="center">
            <Typography variant="subtitle1" gutterBottom>
              <Trans t={t} i18nKey={`${prefix}.description`} />
            </Typography>
            <Grid item sm={8} >
            <PaymentSummary
                {...{
                totalAmount,
                discountedTotalAmount,
                noOfPayments,
                installmentAmount,
                depositAmount,
                installmentFrequency:
                    paymentPlanFrequency && paymentPlanFrequency.label,
                paymentPlanStartDate,
                }}
            />
            </Grid>
        </Grid>
        <Grid item md={4} xs={12} className={classes.paddingMedium}>
                <PaymentElement />
            <Grid item xs={12} className={classes.paddingAroundComponents}>
                <Typography variant="subtitle1">
                    See Terms & Conditions <a rel="noreferrer" style={{color:"black"}} target="_blank" href={paymentPlan}>here</a>
                </Typography>
            </Grid>
            <Grid item xs={12} className={classes.paddingAroundComponents}>
                <Paper
                style={{ width: '75%' }}
                className={!isConfirmed ? classes.confirmationRoot : ""}
                variant="outlined"
                >
                    <Grid item >
                      <Typography align="center" >
                        <Checkbox
                            required
                            validate={acceptance()}
                            color="secondary"
                            name="confirmed"
                            label="I Accept"
                            disabled={success}
                        />
                      </Typography>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12} className={classes.paddingAroundComponents} style={{paddingBottom: "20px"}}>
                <Button
                    color="primary"
                    disabled={!stripe || !elements || !isConfirmed || success || isLoading}
                    variant="contained"
                    data-name="StripePaymentPlan.proceed"
                    onClick={handleClick}
                >
                Capture Payment Method
                </Button>
            </Grid>
            { success && <Alert severity='success'>Your payment method was successfully captured.</Alert>}
            { errorMessage && <Alert severity='error'>{errorMessage}</Alert>}
        </Grid>
    </BaseSection>
    );
});

StripePaymentPlan.propTypes = {};

export default StripePaymentPlan;

export function mapToApplication({paymentMethodId, confirmed}) {
    return {
        clientId: paymentMethodId,
        createType: "Subscription",
        paymentPlanTermsAccepted: confirmed ? true : false
    }
}